<template lang="pug">
  .insurance-detail
    .de-icon
    .de-info
      .de-info-span
      span.de-s1.vew 保单号： {{underwrite?underwrite.policyNo:""}}
      .de-info-span
      span.de-s2 投保人数： {{underwrite?Number(underwrite.initPeopleCounts) + Number(underwrite.addPeopleCounts):""}}
      .de-info-span
        span 保险起期：
        span.time {{underwrite?underwrite.insuranceStartTime:""}}
      .de-info-span
        span 保险止期：
        span.time {{underwrite?underwrite.insuranceEndTime:""}}
            
</template>

<script>
export default {
  props: {
    //保险信息
    underwrite: {
      type: Object,
      default: null,
    },
    //投保人数：
    totalNumber: {
      type: Object,
      totalNumber: null,
    },
  },
};
</script>

<style lang="less" scoped>
.insurance-detail {
  width: 100%;
  height: 100%;
  .df;
  .dfc;
  .aic;
  justify-content: space-around;
  .de-icon {
    width: 48%;
    height: 60%;
    background: url("../../assets/image/dis2.png") center 0 no-repeat;
    background-size: contain; //100%会变形 客户提出的
  }
  .de-info {
    font-size: 14px;
    color: #70aaff;
    width: 100%;
    margin-left: 20px;
    .df;
    flex-wrap: wrap;
    & > span {
      line-height: 26px;
    }
    /*.de-s1 {
      width: 60%;
    }
    .de-s2 {
      width: 40%;
    }*/
    .de-info-span {
      line-height: 26px;
      width: 100%;
      .time {
        font-size: 12px;
      }
    }
    .vew {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    white-space: nowrap;
    }
  }
}
</style>
