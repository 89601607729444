<template lang="pug">
  .index-w
    .total-box
      Box(h="7rem" className="my-item-box" v-for="(item,index) in totalList" :key="index")
        div(v-for="item2 in item")
          countTo(class="card-panel-num" :startVal="0" :endVal="totalNumber[Object.keys(item2)[0]]" :duration="1800")
          span.labe {{item2.label}}
    div.ech-box    
      div.left-box
        Box(className="my-item-box2" v-if='isCity')
          div.title-d.box-top-title
            MTitleI(title="保险方案保费占比结构")
          .echart-box
            NoData(v-if='noDataShow' :typeEchar="'pieChart'")
            PieEchart(v-else-if="reFresh" v-bind="pieOpts" :seriesData="premiumProportion")
        Box(className="my-item-box2" v-if='!isCity')
          div.title-d.box-top-title
            MTitleI(title="保险详情")
          .insuranceDetail
            InsuranceDetail(:underwrite="underwrite" :totalNumber="totalNumber")
        Box(className="my-item-box2") 
          div.title-d.box-top-title
            MTitleI(title="月保费趋势图")
          NoData(v-if='noDataShow2' :typeEchar="'histogram'")
          div#eChar1(v-else)
            BarEchart(
              cid="eChar1"
              v-bind="barOpts"
              yAxisName="单位:万元"
              :lineType="true"
              :xAxisData="monthPremium.xData"
              :seriesData="monthPremium.seriesData")
      div.center-box
        div.btn-box(:style="{justifyContent: cityNumber!=cityNumberFlag? 'space-between':'flex-end'}")
          div(@click="onBack" v-show="cityNumber!=cityNumberFlag")
            .btn-view-list 返回
          div(v-if="btnLabel" @click="onCityData") 
            .btn-view-list
              span(v-if="btnLabel.length===4" style='padding:10px') {{btnLabel}} 
              span(v-if="btnLabel.length>4") {{btnLabel.slice(0,3)}} 
              span(v-if="btnLabel.length>4") {{btnLabel.slice(3)}} 
        div.area-list(v-show="cityNumber===330100")
          span(v-for='(item,index) in areaList') {{index+1}}.{{item}}
        div#eMap
          MapEchart(cid="eMap" :dataList="mapData" :cityNumber="cityNumber" :cityFlagBack="dateTime" @click="onClickMap" @mouseover="onMouseoverMap")
      .right-box
        Box(className="my-item-box2" v-if='isCity')
          div.title-d.box-top-title
            MTitleI(title="保险方案人数占比结构")
          .echart-box
            NoData(v-if='noDataShow1' :typeEchar="'pieChart'")
            PieEchart(v-else-if="reFresh" v-bind="pieOpts" :seriesData="peopleProportion")
        Box(className="my-item-box2" v-if='!isCity')
          div.title-d.box-top-title
            MTitleI(title="承保保险公司")
          .insuranceCompany
            InsuranceCompany(:data="currentCity")
        Box(className="my-item-box2")
          div.title-d.box-top-title
            MTitleI(title="保险方案")
          .my-table-module
            NoData(v-if='noDataShow3' :typeEchar="'table'")
            MyTable(v-else :columns="columns" :tableData="insurancePlanList")
    MyModal(:modalShow="dialogVisible" :title="btnLabel" :modal="true" @onClose="onClose")
      MyTable(className="mymo-table" :columns="tableColumns" :tableData="cityList" :split="true")
</template>

<script>
import Box from "../../components/Box.vue";
import CountTo from "vue-count-to";
import PieEchart from "../../components/PieEchart.vue";
import BarEchart from "../../components/BarEchart.vue";
import MapEchart from "../../components/MapEchart.vue";
import MyTable from "../../components/MyTable.vue";
import MyTable1 from "../../components/MyTable1.vue";
import MyModal from "../../components/MyModal.vue";
import NoData from "../../components/NoData.vue";
import InsuranceDetail from "../module/insuranceDetail.vue";
import InsuranceCompany from "../module/insuranceCompany.vue";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      cityNumber: 330000, //城市code
      cityNumberFlag: 330000, //城市code
      parentCityCode: 0, //父级城市code
      noDataShow: false,
      noDataShow1: false,
      noDataShow2: false,
      noDataShow3: false,
      reFresh: false,
      onlyDistrict: false, //是否是区县地图模块
      isCity: true, //是否是区县
      dateTime: 0,
      dialogVisible: false, //modal 显示隐藏
      areaList: ["拱墅区", "上城区", "西湖区", "滨江区"],
      columns: [],
      tableData: [
        {
          n: "保费",
          n1: "60万元",
          n2: "60万元",
          n3: "60万元",
          n4: "60万元",
        },
      ],
      totalList: [
        [
          { totalPremium: 1231, label: "总保费(元)" },
          { unifyPremium: 1231, label: "已续转保费(元)" },
          { disunityPremium: 1231, label: "待续转保费(元)" },
        ],
        [
          { totalPeopleCounts: 1231, label: "总参保人数" },
          { unityPeopleCounts: 1231, label: "已续转人数" },
          { disunityPeopleCounts: 1231, label: "待续转人数" },
        ],
      ],
      //柱状图配置
      barOpts: {
        stack: false,
        rightyAxisShow: false,
        splitLine: false,
        minInterval: 0,
        barWidth: 16,
        yAxisName: "单位:万元",
        legendData: ["已续转保费", "待续转保费"],
        legend: {
          icon: "roundRect",
          top: "6%",
          right: "0",
          textStyle: {
            color: "#70AAFF",
          },
        },
        yaxisLineShow: false,
        axisTick: false,
        XaxisLineShow: false,
        seriesBarColor: [
          [
            {
              offset: 0,
              color: "#67EBFF",
            },
            {
              offset: 1,
              color: "#468CFF",
            },
          ],
          [
            {
              offset: 0,
              color: "#FFE25C",
            },
            {
              offset: 1,
              color: "#FFBC46",
            },
          ],
        ],
        grid: {
          show: true,
          containLabel: true,
          borderColor: "#0A2F80",
          top: "23%",
          right: "2%",
          bottom: "0",
        },
      },
      seriesData: [],
      //饼图配置
      pieOpts: {
        labelFormatter: "{b}{d}%",
        labelFontSize: 10,
        labelLineShow: true,
        legShow: false,
        labelShow: true,
        // legData: [],
        legOrient: "horizontal",
        labelPosition: "outside",
        radius: ["33%", "50%"],
        legTop: "86%",
        legLeft: "center",
        center: ["50%", "50%"],
        seriesItemLabel: {
          formatter: (params) => {
            return (
              "{name|" +
              params.name +
              "}\n{hr|}\n{percent|" +
              params.percent +
              "%} "
            );
          },
          distanceToLabelLine: 0,
          padding: [-2, 0, 0, 0],
          rich: {
            name: {
              color: "#fff",
              fontSize: 12,
              padding: [6, 10],
              align: "left",
            },
            percent: {
              color: "#fff",
              align: "center",
              fontSize: 12,
              padding: [5, 10],
            },
            hr: {
              borderColor: "#fff",
              width: "100%",
              borderWidth: 0.5,
              height: 0,
            },
          },
        },
        otherOptions: {
          labelLine: {
            length: 20,
            length2: 0,
          },
        },
      },
      PieSeciesData: [
        //饼图数据
        { name: "方案一", value: 23 },
        { name: "方案二", value: 23 },
        { name: "方案三", value: 23 },
        { name: "待续转", value: 23 },
        { name: "方案四", value: 23 },
      ],
      currentCity: null,
    };
  },
  components: {
    Box,
    CountTo,
    PieEchart,
    BarEchart,
    MapEchart,
    MyTable,
    MyTable1,
    MyModal,
    NoData,
    InsuranceDetail,
    InsuranceCompany,
  },
  watch: {
    //保险方案人数占比结构
    peopleProportion(newVal) {
      this.reFresh = false;
      this.$nextTick(() => {
        this.reFresh = true;
      });
      if (!newVal || newVal.length === 0) {
        this.noDataShow1 = true;
      } else {
        this.noDataShow1 = false;
      }
    },
    //保险方案
    insurancePlanList(newVal) {
      if (!newVal || newVal.length === 0) {
        this.noDataShow3 = true;
      } else {
        this.noDataShow3 = false;
      }
      this.columns = [{ label: "保障项目", prop: "n" }];
      if (newVal && newVal.length > 0 && newVal[0].name1) {
        this.columns.push({ label: newVal[0].name1, prop: "n1" });
      }
      if (newVal && newVal.length > 0 && newVal[0].name2) {
        this.columns.push({ label: newVal[0].name2, prop: "n2" });
      }
      if (newVal && newVal.length > 0 && newVal[0].name3) {
        this.columns.push({ label: newVal[0].name3, prop: "n3" });
      }
      if (newVal && newVal.length > 0 && newVal[0].name4) {
        this.columns.push({ label: newVal[0].name4, prop: "n4" });
      }
    },
    monthPremium(newVal) {
      let newvalB = false;
      newVal.seriesData.map((item) => {
        item.map((t) => {
          if (t !== 0) {
            return (newvalB = true);
          }
        });
      });
      if (!newvalB) {
        this.noDataShow2 = true;
      } else {
        this.noDataShow2 = false;
      }
    },
    premiumProportion(newVal) {
      if (!newVal || newVal.length === 0) {
        this.noDataShow = true;
      } else {
        this.noDataShow = false;
      }
    },
    cityList(newVal) {
      if (newVal) {
        newVal.forEach((e) => {
          if (e.districtCode == this.cityNumber) {
            this.currentCity = this.underwrite;
          }
        });
      }
    },
    years(newVal) {
      this.getUnderwriteStatisticsDetail(newVal); // 获取省数据
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.app.userInfo,
      years: (state) => state.app.years,
      peopleProportion: (state) => state.underwritingData.peopleProportion,
      premiumProportion: (state) => state.underwritingData.premiumProportion,
      monthPremium: (state) => state.underwritingData.monthPremium,
      cityList: (state) => state.underwritingData.cityList,
      mapData: (state) => state.underwritingData.mapData,
      totalNumber: (state) => state.underwritingData.totalNumber,
      insurancePlanList: (state) => state.underwritingData.insurancePlanList,
      underwrite: (state) => state.underwritingData.underwrite,
    }),
    btnLabel() {
      if (this.cityNumber === 330000) {
        return "各市数据";
      }
      if (Number(this.cityNumber.toString().slice(4)) > 0) {
        return "";
      }
      return "各区县数据";
    },
    tableColumns() {
      let columns = [
        { label: "城市", prop: "cityName" },
        { label: "总保费(元)", prop: "totalPremium" },
        { label: "已续转保费(元)", prop: "unifyPremium" },
        { label: "待续转保费(元)", prop: "disunityPremium" },
        { label: "总参保人数", prop: "totalPeopleCounts" },
        { label: "已续转人数", prop: "unityPeopleCounts" },
        { label: "待续转人数", prop: "disunityPeopleCounts" },
      ];
      if (this.cityNumber === 330000) {
        columns[0].label = "城市";
      } else {
        columns[0].label = "区县";
      }
      return columns;
    },
  },
  created() {
    // 判断是否为省级账号，获取省的所有数据
    if (this.userInfo.cityCode === 'C000' && this.userInfo.districtCode === 'D0000') {
      this.cityNumberFlag = 330000; // 是否展示返回按钮 33000 是浙江省的编号 省的时候没有返回按钮
      this.getUnderwriteStatisticsDetail(this.years); // 获取省数据
    }
    // 判断是否为市的账号，获取市的所有数据
    if (this.userInfo.cityCode !== 'C000' && this.userInfo.districtCode === 'D0000') {
      // 判断是否展示返回按钮，如果是市的账号，展示市的时候，没有返回按钮
      this.cityNumberFlag = this.userInfo.cityCode;
      this.onClickMap(330000, Number(this.userInfo.cityCode), this.userInfo.cityCode); // 获取市数据
    }
    // 判断是否为市的账号，获取市的所有数据
    if (this.userInfo.districtCode !== 'D0000') {
      // 判断是否展示返回按钮，如果是区县的账号，展示区县的时候，没有返回按钮
      this.cityNumberFlag = this.userInfo.districtCode;
      this.onClickMap(Number(this.userInfo.cityCode), Number(this.userInfo.districtCode), null); // 获取区县数据
    }
  },
  methods: {
    ...mapActions(["_getUnderwriteStatisticsDetail"]),
    //地图点击事件
    onClickMap(parentCityCode, value, isCity) {
      if (this.onlyDistrict) return; //区县级地图点击事件禁止
      this.isCity = isCity;
      this.parentCityCode = parentCityCode;
      this.cityNumber = value;
      this.getUnderwriteStatisticsDetail(this.years);
    },
    onMouseoverMap(e) { },
    onBack() {
      this.dateTime = Date.parse(new Date());
      this.cityNumber = this.parentCityCode;
      if (this.parentCityCode !== 330000) {
        this.parentCityCode = 330000;
        this.onlyDistrict = false;
        this.isCity = true;
      }
      this.getUnderwriteStatisticsDetail(this.years);
    },
    //获取页面详情数据
    getUnderwriteStatisticsDetail(year) {
      let params = {};
      if (this.cityNumber !== 330000) {
        if (!this.isCity) {
          //区县
          params.cityCode = this.parentCityCode.toString();
          params.districtCode = this.cityNumber.toString();
        } else {
          //市
          params.cityCode = this.cityNumber.toString();
        }
      }
      params.year = year
      this._getUnderwriteStatisticsDetail(params);
    },
    //modal 关闭回调
    onClose() {
      this.dialogVisible = false;
    },
    //查看地区数据
    onCityData() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.index-w {
  display: flex;
  flex-direction: column;
}
.insuranceCompany,
.insuranceDetail,
#eMap,
.echart-box,
#eChar1,
#eChar2,
#eChar3 {
  flex: 1;
  width: 100%;
}
.mymo-table {
  /deep/ .thead {
    color: #fff;
  }
}
.my-item-box2 {
  display: flex;
  flex-direction: column;
  .my-table-module {
    flex: 1;
    overflow: hidden;
    max-height: 15.3rem;
  }
}
.title-d {
  padding: 10px 0 15px 15px;
}
.ech-box {
  flex: 1;
  .df;
  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.total-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .labe {
    margin-bottom: 4px;
  }
  & > div:first-child {
    margin-right: 10px;
  }
  & > div:last-child {
    margin-left: 10px;
  }
  .my-item-box {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin-bottom: 6px;
    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      .label {
        // padding-left: 16px;
      }
    }
    & > div:not(:last-child) {
      border-right: 1px solid #1c2a75;
      margin-right: 25px;
    }
  }
  .span-unit,
  .card-panel-num {
    font-size: 64px;
    color: #f5ef41;
    font-family: cheap;
    transform: scaleX(0.5);
    position: absolute;
    left: -4.5rem;
    width: 100%;
    top: 0;
  }
}
.right-box,
.left-box {
  width: 22%;
  & > div {
    width: 100%;
    flex: 1;
  }
  & > div:nth-child(1) {
    margin-bottom: 1.2rem;
  }
}
.right-box {
  & > div {
    float: left;
  }
}
.center-box {
  flex: 1;
  position: relative;
  max-height: 70vh;
  .area-list {
    position: absolute;
    color: #2262b1;
    display: flex;
    flex-direction: column;
    top: 16%;
    left: 20px;
    line-height: 30px;
  }
}
</style>
