<template lang="pug">
  .insurance-company
    .co-icon
    .co-info {{data!=null?data.issueCompany:""}} 
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
};
</script>

<style lang="less" scoped>
.insurance-company {
  width: 100%;
  height: 100%;
  .df;
  .dfc;
  .aic;
  justify-content: space-around;
  .co-icon {
    width: 60%;
    height: 60%;
    background: url("../../assets/image/dis1.jpg") center 0 no-repeat;
    background-size: contain; //100%会变形 客户提出的
    margin-top: 20px;
    background-position: center;
  }
  .co-info {
    font-size: 20px;
    color: #5294f5;
    line-height: 30px;
  }
}
</style>
